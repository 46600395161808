import React from 'react'
import './ProofPayment.scss'
import image from '../../assets/pexels-alena-shekhovtcova-6075014.jpg'
import Table from '../../components/tables/Table'
export default function ProofPayment() {
  return (
    <>
    <div id='main-proof-payment-page' style={{ backgroundImage: `url(${image})` }}>
      <div className='filter'>

      <h1 className='title-page'>Proof of Payment</h1>
      </div>
    </div>
      <Table/>
      </>
  )
}
