import React from 'react'
import './Table.scss'
export default function Table() {
  return (
    <div id='main-table-section'>
        
        <table class="table table-striped table-hover style-table">
  <thead>
    <tr>
      <th scope="col">ID</th>
      <th scope="col">Username</th>
      <th scope="col">Status</th>
      <th scope="col">Amount</th>
      <th scope="col">Date</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>114674</td>
      <td>ahm*****</td>
      <td>Complete</td>
      <td>$3.40</td>
      <td>2024-09-03 15:56:07</td>
    </tr>
    <tr>
     
      <td>114673</td>
      <td>ete*****</td>
      <td>Complete</td>
      <td>$1.433333</td>
      <td>2024-09-03 15:33:04</td>
    </tr>
    <tr>
      <td>114674</td>
      <td>ahm*****</td>
      <td>Complete</td>
      <td>$3.40</td>
      <td>2024-09-03 15:56:07</td>
    </tr>
    <tr>
     
      <td>114673</td>
      <td>ete*****</td>
      <td>Complete</td>
      <td>$1.433333</td>
      <td>2024-09-03 15:33:04</td>
    </tr>
    <tr>
      <td>114674</td>
      <td>ahm*****</td>
      <td>Complete</td>
      <td>$3.40</td>
      <td>2024-09-03 15:56:07</td>
    </tr>
    <tr>
     
      <td>114673</td>
      <td>ete*****</td>
      <td>Complete</td>
      <td>$1.433333</td>
      <td>2024-09-03 15:33:04</td>
    </tr>
    <tr>
      <td>114674</td>
      <td>ahm*****</td>
      <td>Complete</td>
      <td>$3.40</td>
      <td>2024-09-03 15:56:07</td>
    </tr>
    <tr>
     
      <td>114673</td>
      <td>ete*****</td>
      <td>Complete</td>
      <td>$1.433333</td>
      <td>2024-09-03 15:33:04</td>
    </tr>
    <tr>
      <td>114674</td>
      <td>ahm*****</td>
      <td>Complete</td>
      <td>$3.40</td>
      <td>2024-09-03 15:56:07</td>
    </tr>
    <tr>
     
      <td>114673</td>
      <td>ete*****</td>
      <td>Complete</td>
      <td>$1.433333</td>
      <td>2024-09-03 15:33:04</td>
    </tr>
    <tr>
      <td>114674</td>
      <td>ahm*****</td>
      <td>Complete</td>
      <td>$3.40</td>
      <td>2024-09-03 15:56:07</td>
    </tr>
    <tr>
     
      <td>114673</td>
      <td>ete*****</td>
      <td>Complete</td>
      <td>$1.433333</td>
      <td>2024-09-03 15:33:04</td>
    </tr>
   
  </tbody>
</table>

    </div>
  )
}
