import React from 'react'
import './ProfitDashboard.scss'
import image from '../../../../assets/play-games.webp'
export default function ProfitDashboard() {
  return (
    <div id='profit-dashboard'>
      <div className="container-profit">
          <img src={image} alt="" className='image-card'/>
          <h1 className='title'> تصفح الروابط </h1>
          <button className='play-button'>يكسب</button>
        </div>
        <div className="container-profit">
          <img src={image} alt="" className='image-card'/>
          <h1 className='title'>العب العاب </h1>
          <button className='play-button'>يكسب</button>
        </div>

    </div>
  )
}
