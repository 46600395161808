import React from 'react'
import './HomeDashboard.scss'
import { FaCreditCard } from "react-icons/fa6";

export default function HomeDashboard() {
  return (
    <div id='home-dashboard-page'>
        <div className="container-way">
<h1 className='number'>0596</h1>
<p className='title'>الرصيد</p>
<div className="container-withdrawal">
<FaCreditCard className='icon'/>
<h4>سحب</h4>
</div>
        </div>
        <div className="container-way">
<h1 className='number'>0596</h1>
<p className='title'>الرصيد</p>
        </div>
        <div className="container-way">
<h1 className='number'>0596</h1>
<p className='title'>هذا الاسبوع</p>
        </div>
        <div className="container-way">
<h1 className='number'>اخر سحب</h1>
<p className='title'>5655</p>
        </div>

    </div>
  )
}
