import React from 'react'
import './Table2.scss'
import flag from '../../assets/t_500x300.jpeg'
export default function Table2() {
  return (
    <div id='main-table-section'>
        
        <table class="table table-striped table-hover style-table2">
  <thead>
    <tr>
      <th scope="col">Country</th>
      <th scope="col">	Rates/1000</th>
    </tr>
  </thead>
  <tbody className='body-table'>
    <tr className='row-table'>
      <td>
      <div className="container-country-flag">
        <img src={flag} alt="" className='flag-image'/>
      Germany
        </div>
        </td>
      <td>$6.4</td>
    </tr>
  </tbody>
</table>

    </div>  )
}
