import React, { useState } from 'react';
import './Accordion.scss'; // Import the SCSS file
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";

const AccordionItem = ({ title, content, isOpen, onClick }) => {
  return (
    <div className={`container accordion-item ${isOpen ? 'open' : ''}`}>
      <div className="accordion-title" onClick={onClick}>
        <h3>{title}</h3>
        <span>{isOpen ? <IoIosArrowDown /> : <IoIosArrowUp />}</span>
      </div>
      {isOpen && <div className="accordion-content">{content}</div>}
    </div>
  );
};

const Accordion = ({ data }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const handleAccordionClick = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="accordion">
        <div className="container-heading">
          <h1 className="heading">الاسألة الاكثر شيوعا</h1>
          <p className="description">
            احصل على أقصى استفادة من مكافآتك عبر الإنترنت معنا. استفد من
            المدفوعات السريعة، وأعلى معدلات CPM، وتجربة مستخدم لا مثيل لها. نحن
            نتمتع بثقة لأكثر من 5 سنوات، ونعتبر بوابتك إلى تحقيق أرباح رقمية
            معززة. انضم الآن واستمتع بتجربة تحقيق الدخل من عناوين URL بشكل لم
            يسبق له مثيل.
          </p>
        </div>
      {data.map((item, index) => (
        <AccordionItem
          key={index}
          title={item.title}
          content={item.content}
          isOpen={openIndex === index}
          onClick={() => handleAccordionClick(index)}
        />
      ))}
    </div>
  );
};

export default Accordion;
