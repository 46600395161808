import React, { useState } from "react";
import "./SignIn.scss";
// import { Link } from "react-router-dom";
import imageLogo from '../../assets/download.png'
import imageGoogle from '../../assets/google.png'
import imageApple from '../../assets/apple-logo.png'
import imageMicrosoft from '../../assets/microsoft.png'
export default function SignIn() {
  const [name , setName] = useState('');
  const [password , setPassword] = useState('');


  return (
    <>
      <form id="main-sign">

        <img src={imageLogo} alt="" className="image-logo" />
        <h1 className="heading">Welcome to Paidwork</h1>
        <p className="description">Start earning online</p>
       
        <input type="email" placeholder="Email" className="inputs" />
        <input type="password" placeholder="password" className="inputs" />
        <button className="style-button" type="submit">Continue</button>
        <p>OR</p>
        <div className="container-other-way">
          <img src={imageGoogle} alt="" className="images" />
          <p className="title">google</p>
        </div>
        <div className="container-other-way">
          <img src={imageApple} alt="" className="images" />
          <p className="title">Apple</p>
        </div>
        <div className="container-other-way">
          <img src={imageMicrosoft} alt="" className="images" />
          <p className="title">Microsoft</p>
        </div>
        </form>
     
    </>
  );
}
