import React from 'react'
import './Login.scss'
import imageLogo from '../../assets/download.png'
import imageGoogle from '../../assets/google.png'
import imageApple from '../../assets/apple-logo.png'
import imageMicrosoft from '../../assets/microsoft.png'
export default function Login() {
  return (
    <>
<div id="main-login">
<img src={imageLogo} alt="" className='image-logo'/>
<h1 className='heading'>Start earning online</h1>
<p className='description'>Welcome to Paidwork</p>
<input type="email" placeholder='Email' className='inputs'/>
<input type="password" placeholder='password' className='inputs'/>
<button className='style-button'>Continue</button>
<p>OR</p>
<div className="container-other-way">
  <img src={imageGoogle} alt="" className='images'/>
  <p className='title'>google</p>
</div>
<div className="container-other-way">
  <img src={imageApple} alt="" className='images' />
  <p className='title'>Apple</p>

</div>
<div className="container-other-way">
  <img src={imageMicrosoft} alt="" className='images'/>
  <p className='title'>Microsoft</p>

</div>
</div>
  </>
  )
}
