import React from "react";
import image from "../../assets/pexels-alena-shekhovtcova-6075014.jpg";
import "./PayoutRates.scss";
import Table2 from "../../components/table2/Table2";
export default function PayoutRates() {
  return (
    <>
      <div
        id="main-proof-payment-page"
        style={{ backgroundImage: `url(${image})` }}
      >
        <div className="filter">
          <h1 className="title-page">Proof of Payment</h1>
          <p className="description">
            We pay to all visitors in any country. we count 1 ip address for 1
            view in 24 hours.
          </p>
          <p className="description">
            Traffic quality affects the CPM rate, the better the quality of
            traffic, the higher CPM gets a few percent of the CPM price
            mentioned below. Similarly, if the quality of traffic is not good,
            CPM can be reduced by a few percent from the CPM price mentioned
            below.
          </p>
          <p className="description">
          Last update at Feb 3, 2021 at 05:18.
          </p>
        </div>
      </div>
      <Table2 />
    </>
  );
}
