import { useLocation } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import Home from './pages/home/Home';
import ProofPayment from './pages/proofPayment/ProofPayment';
import Footer from './components/foooter/Footer';
import Header from './components/header/Header';
import { Route, Routes } from 'react-router-dom';
import PayoutRates from './pages/payoutRates/PayoutRates';
import Login from './pages/login/Login';
import SignIn from './pages/signIn/SignIn';
import DashboardUser from './pages/dashboardUser/DashboardUser';

function App() {
  const location = useLocation();

  return (
    <div className="App">
      {/* Conditionally render Header only if the path is not /admin */}
      {location.pathname !== "/admin" && <Header />}

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/payoutrates" element={<PayoutRates />} />
        <Route path="/proofpayment" element={<ProofPayment />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/admin" element={<DashboardUser />} />
      </Routes>

      {/* Optionally include Footer on specific routes as well */}
      {/* {location.pathname !== "/admin" && <Footer />} */}
    </div>
  );
}

export default App;
