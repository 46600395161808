
import "./Counter.scss"
import CountUp from "react-countup";
export default function Counter(){
return(
    <>
<div id="container-counters" className="container">
<div className="container-counter">
    <h1  className="counter">
    <CountUp end={616} 
          duration={4}
        enableScrollSpy= {true}
             /> K+
    </h1>
    <p className="title-counter">المستخدمين</p>
</div>
<div className="container-counter">
<h1  className="counter">
<CountUp end={24} 
          duration={2}
        enableScrollSpy= {true}
             />M+
    </h1>
<p className="title-counter">الروابط</p>
</div>
<div className="container-counter">
<h1  className="counter">
<CountUp end={126} 
          duration={3}
        enableScrollSpy= {true}
             />K+
    </h1>
<p className="title-counter">الدفعات</p>
</div>
<div className="container-counter">
<h1  className="counter">
<CountUp end={928} 
          duration={5}
        enableScrollSpy= {true}
             />M+
    </h1>
<p className="title-counter">المشاهدات</p>
</div>
</div>
    </>
)
}